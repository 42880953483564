/* eslint-disable no-console */
const MauticConnector = require('node-mautic');

const mauticConnector = new MauticConnector({
  apiUrl: 'https://www.mautic.vaypol.com.ar/mautic',
  username: 'imartinez',
  password: 'ADMW2022c'
});

const allowMautics = process.env.NEXT_PUBLIC_ALLOW_MAUTIC === 'true';

const addMautic = async (email, cartToken) => {
  try {
    if (allowMautics && email) {
      // traemos la info que tenemos guardada de mautic
      const mauticID = JSON.parse(localStorage.getItem('mauticID'));
      const web = window.location.hostname;
      let id;
      const user = JSON.parse(localStorage.getItem('user'));
      // si el usuario es otro
      if (email !== mauticID?.email) {
        const contacto =
          await mauticConnector.contacts.getContactByEmailAddress(email);
        if (contacto.total === '0') {
          // si no esta registrado en mautic lo creamos y segmentamos
          const newContact = await mauticConnector.contacts.createContact({
            email,
            firstname: user?.name || null,
            website: `${web}/cart?cartToken=${cartToken}`,
            apicall: `${process.env.NEXT_PUBLIC_BASE_URL}/api/v2/storefront/mautic/carts/${cartToken}.rss`,
            site_code: web
          });
          id = newContact.contact.id;
          mauticConnector.segments.addContactToSegment(1, id);
          // guardamos el contacto en localStorage
          localStorage.setItem('mauticID', JSON.stringify({ email, id }));
          return true;
        }
        id = Object.values(contacto.contacts)[0].id;
        // guardamos el contacot en localStorage
        localStorage.setItem('mauticID', JSON.stringify({ email, id }));
      }

      const contacto = await mauticConnector.contacts.getContact(
        id || mauticID.id
      );
      const segmento = await mauticConnector.contacts.getSegmentMemberships(
        id || mauticID.id
      );
      // el segmento "1" es el de carrito abandonado
      if (!segmento.lists['1']) {
        // si no tiene lo agregamos y segmentamos
        mauticConnector.contacts.editContact(
          'PATCH',
          {
            firstname:
              contacto.contact.fields.all.firstname || user?.name || null,
            apicall: `${process.env.NEXT_PUBLIC_BASE_URL}/api/v2/storefront/mautic/carts/${cartToken}.rss`,
            website: `${web}/cart?cartToken=${cartToken}`,
            site_code: web
          },
          id || mauticID.id
        );
        mauticConnector.segments.addContactToSegment(1, id || mauticID.id);
      } else if (
        // vemos si podemos actualizar el cartoken o el nombre del contacto
        contacto.contact.fields.all.website !==
          `http://${web}/cart?cartToken=${cartToken}` ||
        (contacto.contact.fields.all.firstname === null && user?.name)
      ) {
        await mauticConnector.contacts.editContact(
          'PATCH',
          {
            website: `${web}/cart?cartToken=${cartToken}`,
            apicall: `${process.env.NEXT_PUBLIC_BASE_URL}/api/v2/storefront/mautic/carts/${cartToken}.rss`,
            firstname: contacto.contact.fields.all.firstname || user?.name,
            site_code: web
          },
          id || mauticID.id
        );
      }
    }
    return true;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const crossSellMautic = async (email, url, product) => {
  try {
    if (email) {
      const web = window.location.hostname;
      const contacto = await mauticConnector.contacts.getContactByEmailAddress(
        email
      );
      const { id } = Object.values(contacto.contacts)[0];
      await mauticConnector.segments.removeContactFromSegment(8, id);
      mauticConnector.segments.addContactToSegment(8, id);
      mauticConnector.contacts.editContact(
        'PATCH',
        {
          crosssell_product: `${web}/${url}?crossSell=true`,
          crosssell_site: web,
          last_product: product
        },
        id
      );
    }
  } catch (err) {
    console.log(err);
  }
};

const removeMautic = async () => {
  try {
    const mauticID = JSON.parse(localStorage.getItem('mauticID'));
    if (allowMautics && mauticID.id) {
      mauticConnector.segments.removeContactFromSegment(1, mauticID.id);
      mauticConnector.campaigns.removeContactFromCampaign(5, mauticID.id);
    }
  } catch (err) {
    console.log(err);
  }
};

export default {
  addMautic,
  removeMautic,
  crossSellMautic
};
