import errorHandler from '@/services/error/handler';

export default async function handleCartError(
  error,
  create,
  fn,
  args,
  tryRequest
) {
  if (error.response?.status === 404) {
    tryRequest += 1;
    if (create && tryRequest < 2) {
      const newCart = await create();
      if (fn) return fn(...args, tryRequest);
      return newCart;
    }
  }
  let errors;
  if (create) {
    errors = errorHandler(error);
  }
  return {
    success: false,
    errors
  };
}
