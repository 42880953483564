import axiosDefault from 'axios';
// import { setCookies } from '@/services/vaypolCookies/conections';
import axios from '../../services/axiosInstance';
import { setCookie, getCookies } from '../../services/cookies';
import errorHandler from '../../services/error/handler';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const forgotPassword = async (email, code) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/recover_password?code=${storeId}`,
      {
        email,
        code
      }
    );
    return {
      success: true,
      data: response
    };
  } catch (err) {
    const message = errorHandler(err);
    return {
      success: false,
      error: message
    };
  }
};

const resetPassword = async (passwordToken, password, passwordConfirmation) => {
  try {
    const response = await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/recover_password`,
      {
        reset_password_token: passwordToken,
        password,
        password_confirmation: passwordConfirmation
      }
    );
    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const message = errorHandler(err);
    return {
      success: false,
      error: message
    };
  }
};

const sendAuthCode = async (email) => {
  try {
    const response = await axios.post(
      `/account/send_auth_code?code=${storeId}`,
      {
        email
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const errors = errorHandler(err);
    return {
      success: false,
      errors
    };
  }
};

const receiveAuthCode = async (email, authCode) => {
  try {
    const response = await axios.get(
      `/account/receive_auth_code?email=${email}&authorization_code=${authCode}`
    );

    const user = {
      name: response.data.username,
      email
    };

    setCookie('token', response.data.access_token);

    setCookie('refreshToken', response.data.refresh_token);

    localStorage.setItem('user', JSON.stringify(user));
    // guardamos las cookies en vaypol-cookies
    // setCookies();

    return {
      success: true,
      data: user
    };
  } catch (err) {
    return {
      success: false,
      errors: [err.response.data.data]
    };
  }
};

const guestEmail = async (email) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/checkout/init_without_login?email=${email}&code=${storeId}`
    );
    // guardamos el token en una cookie
    setCookie('token', response.data.access_token);

    // guardar refresh token
    setCookie('refreshToken', response.data.refresh_token);
    return {
      success: true,
      data: {
        personalInfo: response.data?.user_data?.personal_info,
        billingAddress: response.data?.user_data?.bill_address,
        shippingAddress: {
          ...response.data?.user_data?.ship_address,
          store_branch_id: response.data?.user_data?.store_branch_id
        },
        persisted: response.data.persisted,
        logged: response.data.guest
      }
    };
  } catch (err) {
    const errors = errorHandler(err);

    return {
      success: false,
      errors
    };
  }
};

const autoLogin = async (context) => {
  try {
    const response = await axios.get(`/account/profile?code=${storeId}`, {
      context
    });

    return {
      success: true,
      data: {
        personalInfo: {
          ...response.data.data.personal_information,
          email: response.data.data.account.email
        },
        billingAddress: response.data.data.bill_address,
        shippingAddress: {
          ...response.data.data.ship_address,
          store_branch_id:
            response.data.data.personal_information.store_branch_id
        },
        logged: true,

        persisted: response.data.data.persisted
      }
    };
  } catch (err) {
    const errors = errorHandler(err);

    return {
      success: false,
      errors
    };
  }
};

const login = async (email, password) => {
  try {
    const response = await axiosDefault.post(
      `${process.env.NEXT_PUBLIC_BASE_URL}/spree_oauth/token?code=${storeId}`,
      {
        grant_type: 'password',
        username: email,
        password,
        code: storeId
      }
    );

    const user = {
      name: response.data.username,
      email
    };

    // guardamos el token en una cookie
    setCookie('token', response.data.access_token);

    // guardar refresh token
    setCookie('refreshToken', response.data.refresh_token);

    // guardamos para el autoLogin
    localStorage.setItem('user', JSON.stringify(user));

    // si la respuesta tiene cartToken
    if (response.data.cartToken) {
      // buscamos el cartToken en las cookies
      const { cartToken } = getCookies();
      if (!cartToken) setCookie('cartToken', response.data.cartToken);
    }
    // guardamos las cookies en vaypol-cookies
    // setCookies();

    return {
      success: true,
      data: {
        personalInfo: response.data?.user_data?.personal_info,
        billingAddress: response.data?.user_data?.bill_address,
        shippingAddress: {
          ...response.data?.user_data?.ship_address,
          store_branch_id: response.data?.user_data?.store_branch_id
        },
        logged: true,
        persisted: response.data.persisted
      }
    };
  } catch (err) {
    const { errors } = err.response.data;

    return {
      success: false,
      errors
    };
  }
};

const validateEmail = async (email, finish) => {
  try {
    const response = await axios.get(
      `/account/email_validation?email=${email}&code=${storeId}${
        finish ? '&finish=true' : ''
      }`
    );

    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    const { errors } = err.response.data;

    return {
      success: false,
      errors
    };
  }
};

const signUp = async (user, eventId) => {
  try {
    await axios.post(
      `/account?code=${storeId}&isGuest=${user.isGuest}&event_id=${eventId}`,
      {
        user
      }
    );
    return login(user.email, user.password);
  } catch (err) {
    const { errors } = err.response.data;

    return {
      success: false,
      errors
    };
  }
};

export default {
  guestEmail,
  autoLogin,
  login,
  validateEmail,
  forgotPassword,
  sendAuthCode,
  receiveAuthCode,
  signUp,
  resetPassword
};
